* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif, "Roboto";
}

.top {
  font-family: "Dancing Script", cursive, "Inter", sans-serif, "Roboto";
  width: 45%;
  text-align: left;
  margin-left: 21px;
  color: rgb(30, 87, 40);
  font-size: 4.8em;
  font-weight: 700;
  user-select: none;
}

.story {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 1.6em;
  font-weight: 300;
  width: 94%;
  text-align: left;
  margin-left: 21px;
  border-bottom: 1px solid rgb(30, 87, 40);
  user-select: none;
}

.form {
  max-width: 45%;
  margin: 20px auto;
  padding: 30px 50px 30px 50px;
  user-select: none;
}

.form label {
  margin: 5px;
  font-size: 1.4em;
  display: block;
  padding: 5px 15px;
  font-weight: 500;
}
.form span {
  display: flex;
  font-size: 1em;
  margin-top: 15px;
}

.form input,
textarea {
  display: table;
  padding: 8px 6px;
  font-size: 0.9em;
  width: 100%;
  justify-content: center;
  border-radius: 4px;
  border-color: rgb(104, 102, 102);
  border-width: 1.9px;
  color: rgb(2, 1, 1);
  background-color: rgba(224, 227, 221, 0.646);
}

.form .names {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 50%;
}

.notification {
  font-size: 0.7em;
  color: rgb(241, 5, 5);
  font-weight: 500;
  margin: 0;
  padding: 0;
  position: absolute;
}

.form .names .align {
  flex-basis: 50%;
}

h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.attend {
  width: 15%;
  align-items: left;
  display: flex;
  justify-content: flex-start;
  margin: 20px 0 0px 10px;
  gap: 10px;
}

.format {
  display: flex;
}

.checkbox {
  line-height: 0.7;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(224, 227, 221, 0.646);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.1em;
  height: 1.1em;
  border: 0.02em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-bottom: 0.3em;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.7em;
  height: 0.7em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.no,
.yes {
  font-size: 0.8em;
  font-weight: 500;
}

.box:checked {
  background-color: rgb(180, 214, 186);
  transform: translateY(-3px);
}

p {
  font-size: 1.1em;
  padding-bottom: 25px;
  margin: 0px 80px;
  font-weight: 300;
}

h3 {
  font-size: 0.8em;
  font-weight: 300;
  text-align: left;
  margin-bottom: 5px;
}
.small {
  margin-bottom: 5px;
}

.btn {
  color: #fff;
  background-color: #1e5728;
  font-size: 1.6em;
  padding: 0.5em;
  border-radius: 6px;
  font-weight: 700;
  border: 2px solid rgb(30, 87, 40);
  margin-top: 10px;
  transition: transform 0.3s;
}

.btn:hover {
  color: rgb(251, 149, 15);
  cursor: pointer;
  transform: translateY(-3px);
}

.btnFail {
  background-color: rgb(251, 149, 15);
  color: #fff;
  font-size: 1.6em;
  padding: 0.5em;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
}

.loaded {
  background-color: #1e5728;
  margin-top: 0;
  padding-top: 0;
}

textarea {
  height: 150px;
}

.load {
  padding-top: 10px;
}

.successMessage {
  font-size: 1.3em;
  font-weight: 600;
  padding-top: 20px;
  color: rgb(30, 87, 40);
}

@media (max-width: 1400px) {
  .form {
    max-width: 60%;
  }
}

@media (max-width: 1150px) {
  .form {
    max-width: 75%;
  }
  .top {
    font-size: 3.5em;
  }
}

@media (max-width: 750px) {
  .form {
    max-width: 95%;
    font-size: small;
  }
  .top {
    font-size: 3em;
  }
}

@media (max-width: 550px) {
  .form {
    max-width: 90%;
  }
  .names {
    flex-direction: column;
  }
  .align {
    width: 100%;
  }
  .btn {
    font-size: small;
  }
}

@media (max-width: 530px) {
  .attend {
    display: block;
  }
}

@media (max-width: 460px) {
  .top {
    margin-top: 50px;
  }
  .form {
    max-width: 95%;
    font-size: smaller;
  }
  .names {
    flex-direction: column;
  }
  .align {
    width: 100%;
  }
  .form span {
    flex-direction: column;
    align-items: flex-start;
  }
  .btn {
    font-size: smaller;
  }
  .top {
    font-size: 2.4em;
  }
  .story {
    font-size: 1.3em;
  }
}

@media (max-width: 430px) {
  .format {
    display: flex;
    flex-direction: column;
  }
  .attend {
    margin: 0;
    padding: 0;
  }
  span {
    text-align: left;
  }
}

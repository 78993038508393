* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif, "Roboto";
}

.container {
  width: 55%;
  margin: auto;
  text-align: left;
  user-select: none;
}

.top {
  font-family: "Dancing Script", cursive, "Inter", sans-serif, "Roboto";
  text-align: left;
  padding-top: 60px;
  padding-bottom: 20px;
  color: rgb(30, 87, 40);
  font-size: 4.8em;
  font-weight: 700;
}

.story {
  margin-top: 20px;
  margin-bottom: 60px;
  font-size: 1.7em;
  font-weight: 300;
}

.pictures {
  width: 80%;
  display: flex;
  margin: auto;
  justify-content: space-around;
  margin-top: 15px;
  gap: 30px;
  flex-wrap: wrap;
}

.lots {
  margin-top: 15px;
  width: 330px;
  height: 330px;
}

.lots:last-child {
  margin-bottom: 50px;
}

@media (max-width: 1400px) {
  .container {
    width: 80%;
  }
  .pictures {
    width: 95%;
  }
}

@media (max-width: 1100px) {
  .container {
    width: 80%;
  }
  .top {
    font-size: 4.1em;
  }
  .pictures {
    width: 95%;
    gap: 10px;
  }
}

@media (max-width: 1070px) {
  .container {
    width: 80%;
  }
  .top {
    font-size: 3.7em;
  }
  .story {
    font-size: 1.5em;
  }
  .lots {
    margin-top: 10px;
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 810px) {
  .top {
    font-size: 3em;
  }
  .story {
    font-size: 1.2em;
  }
  .lots {
    margin-top: 10px;
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 570px) {
  .top {
    font-size: 2em;
  }
  .story {
    font-size: 1em;
  }
  .lots {
    margin-top: 10px;
    width: 230px;
    height: 230px;
  }
}
